"use client";

export default function Logo() {
	return (
		<a href="/" className="-m-1.5 p-1.5">
			<span className="sr-only">KSaarela</span>
			<img
				alt=""
				src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
				className="h-8 w-auto"
			/>
		</a>
	);
}
